import { messages as revenuePageMessages } from "jobber/setupWizard/components/RevenuePage/components/messages";
import { messages as businessPageMessages } from "jobber/setupWizard/components/BusinessPage/components/messages";
import { messages as heardAboutUsPageMessages } from "jobber/setupWizard/components/HeardAboutUsPage/messages";
import { messages as profilePageMessages } from "jobber/setupWizard/components/ProfilePage/messages";
import { messages as communityPageMessages } from "jobber/setupWizard/components/CommunityPage/messages";
import { messages as topPriorityPageMessages } from "jobber/setupWizard/components/TopPriorityPage/messages";
import type { MessageFormat } from "../types";

/**
 * Any changes to the values of this array (case-sensitive) must be coordinated with BizOps due to HubSpot integration
 */
export const companySizeArray: readonly {
  value: string;
  label: MessageFormat;
}[] = [
  { value: "Just me", label: businessPageMessages.companySizeJustMe },
  { value: "2-3 people", label: businessPageMessages.companySize2To3People },
  { value: "4-10 people", label: businessPageMessages.companySize4To10People },
  {
    value: "11-19 people",
    label: businessPageMessages.companySize11To19People,
  },
  { value: "20+ people", label: businessPageMessages.companySize20PlusPeople },
];

export const companyAgeArray: readonly {
  value: string;
  label: MessageFormat;
}[] = [
  { value: "<1 year", label: businessPageMessages.companyAgeLessThan1Year },
  { value: "1-2 years", label: businessPageMessages.companyAge1To2Years },
  { value: "3-5 years", label: businessPageMessages.companyAge3To5Years },
  { value: "6-10 years", label: businessPageMessages.companyAge6To10Years },
  { value: "10+ years", label: businessPageMessages.companyAge10PlusYears },
];

/**
 * Any changes to the values of this array (case-sensitive) must be coordinated with BizOps due to HubSpot integration
 */
export const companyRevenueArray: readonly {
  value: string;
  label: MessageFormat;
}[] = [
  { value: "$0 - $50,000", label: revenuePageMessages.companyRevenue0To50K },
  {
    value: "$50,000 - $150,000",
    label: revenuePageMessages.companyRevenue50To150K,
  },
  {
    value: "$150,000 - $500,000",
    label: revenuePageMessages.companyRevenue150To500K,
  },
  {
    value: "$500,000 - $2,000,000",
    label: revenuePageMessages.companyRevenue500KTo2M,
  },
  { value: "$2,000,000+", label: revenuePageMessages.companyRevenue2MPlus },
  {
    value: "I'd prefer not to say",
    label: revenuePageMessages.companyRevenuePreferNotToSay,
  },
];

/**
 * Any changes to the values of this array (case-sensitive) must be coordinated with BizOps due to HubSpot integration
 */
export const topPriorityArray: { value: string; label: MessageFormat }[] = [
  {
    value: "Win more work",
    label: topPriorityPageMessages.topPriorityGetMoreCustomers,
  },
  {
    value: "Increase efficiency",
    label: topPriorityPageMessages.topPriorityIncreaseEfficiency,
  },
];

export const lastSubmittedStepQuestionKey = "Last submitted step";

export const heardAboutUsPageDefaultFeatureList = [
  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem1Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem1Subtext,
  },
  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem2Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem2Subtext,
  },

  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem3Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem3Subtext,
  },
  {
    header: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem4Header,
    subtext: heardAboutUsPageMessages.heardAboutUsPageFeatureListItem4Subtext,
  },
];

export const communityInfo = [
  {
    header: communityPageMessages.communityDefaultItem1Header,
    subtext: communityPageMessages.communityDefaultItem1Subtext,
  },
  {
    header: communityPageMessages.communityDefaultItem2Header,
    subtext: communityPageMessages.communityDefaultItem2Subtext,
  },
  {
    header: communityPageMessages.communityDefaultItem3Header,
    subtext: communityPageMessages.communityDefaultItem3Subtext,
  },
];

export const profilePageDefaultStats = [
  {
    copy: profilePageMessages.profilePageDefaultStatsCopy1,
    maskingTape: "one" as const,
  },
  {
    copy: profilePageMessages.profilePageDefaultStatsCopy2,
    color: "highlight",
    maskingTape: "two" as const,
  },
  {
    copy: profilePageMessages.profilePageDefaultStatsCopy3,
    maskingTape: "three" as const,
  },
] as const;

export const communityForum = "CommunityForum";
