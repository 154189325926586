import React, { useCallback } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useIntl } from "react-intl";
import { jobberOnline } from "components/JobberOnline/jobberOnline";
import type { AutocompleteCompany as AutocompleteCompanyType } from "jobber/setupWizard/types";
import { AutocompleteCompany } from "./components/AutocompleteCompany";
import { messages } from "./messages";

interface GoogleCompanyAutocompleteProps {
  invalid: boolean;
  onChange: (company: AutocompleteCompanyType) => void;
  value: string | undefined;
  valueLabel: string | undefined;
  signupConsentCountry: string | undefined;
}

export function GoogleCompanyAutocomplete({
  invalid,
  onChange,
  value,
  valueLabel,
  signupConsentCountry,
}: GoogleCompanyAutocompleteProps) {
  const { formatMessage } = useIntl();
  const apiKey = jobberOnline.constants.googleCloudPlatformKey;

  const onCompanyChange = useCallback(
    (company: AutocompleteCompanyType) => {
      onChange(company);
    },
    [onChange],
  );

  return (
    <Wrapper apiKey={apiKey} libraries={["core", "places"]}>
      <AutocompleteCompany
        onCompanyChange={onCompanyChange}
        placeholder={formatMessage(messages.companyNamePlaceholder)}
        value={value}
        valueLabel={valueLabel}
        name="company"
        invalid={invalid}
        signupConsentCountry={signupConsentCountry}
      />
    </Wrapper>
  );
}
