import { Content } from "@jobber/components/Content";
import { Heading } from "@jobber/components/Heading";
import { Modal } from "@jobber/components/Modal";
import { Tab, Tabs } from "@jobber/components/Tabs";
import deepmerge from "deepmerge";
import React, { useEffect, useReducer, useState } from "react";
import pluralize from "pluralize";
import type {
  ClientNotificationId,
  MessageTemplateFragment,
  Scalars,
  TemplateAttributes,
  UpdateNotificationMutationVariables,
} from "~/utilities/API/graphql";
import { ActionTypes } from "jobber/settings/notifications/ActionTypes";
import { Scheduler } from "jobber/settings/notifications/components/Scheduler/Scheduler";
import { TemplateEditor } from "jobber/settings/notifications/components/TemplateEditor/TemplateEditor";
import type {
  ActionFields,
  BaseNotificationProps,
  ClientNotificationSchedules,
  ClientNotificationTemplates,
  NotificationTypes,
} from "jobber/settings/notifications/notificationInterfaces";
import { ErrorList } from "components/ErrorList/ErrorList";
import { assertHasTypename } from "utilities/types/assertTypes";
import { templateReducer } from "./templateReducer";
import { scheduleReducer } from "./scheduleReducer";
import styles from "./NotificationModal.module.css";

export interface NotificationModalState {
  id: string;
  schedules: ClientNotificationSchedules;
  templates: ClientNotificationTemplates;
}

/**
 * Question…
 *
 * How should we handle the many different kinds of types that action.value
 * can be in here?
 */

function initNotificationModalReducer(notification: NotificationTypes) {
  return deepmerge({}, notification);
}

const notificationModalReducer = (
  state: NotificationModalState,
  action: ActionFields,
) => {
  switch (action.type) {
    case ActionTypes.Reset:
      return initNotificationModalReducer(action.value as NotificationTypes);

    default:
      return {
        ...state,
        ...{ schedules: scheduleReducer(state.schedules, action) },
        ...{ templates: templateReducer(state.templates, action) },
      };
  }
};

interface NotificationModalProps
  extends Omit<BaseNotificationProps, "description"> {
  open: boolean;
  backActionType?: "cancel" | "back";
  saveText?: string;
  onRequestClose(backAction: "close" | "save" | "back"): void;
  onSave(
    params: UpdateNotificationMutationVariables,
  ): Promise<{ data?: unknown; errors: string[] }>;
}

export function NotificationModal({
  title,
  open,
  scheduleTemplate,
  scheduleFootnote,
  allowDeliveryMethodChange = true,
  allowUnitChange = true,
  notification: initialNotification,
  onRequestClose,
  onSave,
  saveText,
  textRewriteVariableSet,
  backActionType = "cancel",
}: NotificationModalProps) {
  const [
    {
      id,
      schedules: { nodes: schedules },
      templates: { nodes: templates },
    },
    dispatch,
  ] = useReducer(
    notificationModalReducer,
    initialNotification,
    initNotificationModalReducer,
  );
  const [mutationLoading, setMutationLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([] as string[]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.Reset,
      id,
      value: initialNotification,
    });
    setErrorMessages([]);
  }, [open]);

  const handleSave = async () => {
    setMutationLoading(true);
    const savePayload = generateSavePayload(id, schedules, templates);
    try {
      const response = await onSave(savePayload.variables);
      setMutationLoading(false);

      setErrorMessages(response.errors);
      if (response.data && response.errors.length === 0) {
        requestClose("save");
      }
    } catch {
      setMutationLoading(false);
      setErrorMessages(["Something went wrong. Please try again."]);
    }
  };

  const showSchedules = schedules.length > 0;

  const cancelOrBackAction = {
    [backActionType === "back" ? "tertiaryAction" : "secondaryAction"]: {
      label: backActionType === "back" ? "Back" : "Cancel",
      onClick: () => requestClose(backActionType === "back" ? "back" : "close"),
      variation: "subtle",
      disabled: mutationLoading,
      type: backActionType === "back" ? "primary" : undefined,
    },
  };
  const contentNotEmpty = errorMessages.length > 0 || showSchedules;

  return (
    <Modal
      title={`Edit ${title}`}
      open={open}
      onRequestClose={() => requestClose("close")}
      size="large"
      primaryAction={{
        label: mutationLoading ? "Saving…" : saveText || "Save",
        onClick: handleSave,
        loading: mutationLoading,
      }}
      {...cancelOrBackAction}
    >
      {contentNotEmpty && (
        <Content>
          <ErrorList errors={errorMessages} />
          {showSchedules && (
            <>
              <Heading level={4}>
                {pluralize("Schedule", schedules.length)}
              </Heading>
              <Scheduler
                scheduleTemplate={scheduleTemplate}
                scheduleFootnote={scheduleFootnote}
                schedules={schedules}
                allowUnitChange={allowUnitChange}
                allowDeliveryMethodChange={allowDeliveryMethodChange}
                onDispatch={dispatch}
                type={initialNotification.id}
              />
              <Heading level={4}>Templates</Heading>
            </>
          )}
        </Content>
      )}
      <div className={styles.noOverflow}>
        <Tabs>
          {templates.map(template => (
            <Tab
              key={template.id}
              label={getTabNames(template.name, template.deliveryMethod)}
            >
              <div className="gridContainer u-paddingNone">
                <TemplateEditor
                  template={template}
                  onDispatch={dispatch}
                  textRewriteVariableSet={textRewriteVariableSet}
                />
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
    </Modal>
  );

  function requestClose(action: "close" | "save" | "back") {
    if (!mutationLoading) {
      onRequestClose(action);
    }
  }
}

function getTabNames(name: string, method: string) {
  const nameMap: { [key: string]: string } = {
    EMAIL: "Email",
    SMS: "Text Message",
  };

  if (name === "Visit" || name === "Assessment") {
    return `${name} ${nameMap[method]}`;
  } else {
    return nameMap[method];
  }
}

function generateSavePayload(
  id: Scalars["EncodedId"]["input"],
  schedules: ClientNotificationSchedules["nodes"],
  templates: MessageTemplateFragment[],
) {
  return {
    variables: {
      id: id as ClientNotificationId,
      data: {
        templates: templates.map(template => {
          const convertedTemplate: TemplateAttributes = {
            id: template.id,
            message: template.message.current,
          };

          assertHasTypename(template);

          if (template.__typename !== "MessageTemplateSMS") {
            convertedTemplate.subject = template.subject
              ? template.subject.current
              : "";
          }

          if (template.__typename === "MessageTemplateSurvey") {
            convertedTemplate.survey = template.survey
              ? template.survey.current
              : "";

            convertedTemplate.surveyEnabled = template.surveyEnabled;
          }

          return convertedTemplate;
        }),
        schedules: schedules.map(schedule => {
          let offset;

          if (schedule.offset) {
            offset = {
              value: schedule.offset.value,
              unit: schedule.offset.unit,
            };
          }

          return {
            id: schedule.id,
            enabled: schedule.enabled,
            deliveryMethod: schedule.deliveryMethod,
            at: schedule.at,
            offset: offset,
          };
        }),
      },
    },
  };
}
