import { Amplitude } from "~/utilities/analytics/Amplitude";
import { Intercom } from "utilities/chat";
import { useCloseDrawer } from "jobber/jobberAssistant/hooks/useCloseDrawer";
import { useCreateIntercomConversation } from "jobber/jobberAssistant/hooks/useCreateIntercomConversation";

export const useStartChatWithSuccess = () => {
  const closeChatSidebar = useCloseDrawer();
  const { createIntercomConversation, loading } =
    useCreateIntercomConversation();

  const startChatWithSuccess = async (message: string, requestId: string) => {
    try {
      Amplitude.TRACK_EVENT("Interacted with Jobber Assistant", {
        name: "chat_with_success_clicked",
        requestId,
      });

      const response = await createIntercomConversation(message, requestId);
      if (response) {
        callSupportChatTwice("showConversation", response);
      } else {
        callSupportChatTwice("showNewMessage", message);
      }
    } catch {
      callSupportChatTwice("showNewMessage", message);
      // eslint-disable-next-line no-console
      console.error("Failed to create intercom conversation");
    } finally {
      closeChatSidebar?.();
    }
  };

  return {
    startChatWithSuccess,
    loading,
  };
};

function callSupportChatTwice(methodName: string, argument: string) {
  /* We intentionally call the method twice because there is a bug in the Intercom,
     JS API that sometimes causes the Intercom modal to open to the wrong screen.
     A bug for this has been filed with Intercom, but for now a workaround is to
     call the API twice.
  */
  Intercom.EXECUTE(methodName, argument);
  Intercom.EXECUTE(methodName, argument);
}
