import type {
  AutocompleteCompany,
  TrackedAutocompleteCompanyData,
} from "../../types";

export const googleAutocompleteTrackingData = (
  company: AutocompleteCompany,
) => {
  const photosByCompany = getPhotosByCompany(company.photos, company.name);

  const review = getHighestReview(company.reviews);
  const dataToTrack: TrackedAutocompleteCompanyData = {
    name: company.name || "",
    website: company.website,
    photoUrl: photosByCompany?.url,
    photoByCompanyCount: photosByCompany?.count,
    ...(review && {
      review: {
        rating: review.rating,
        text: review.text,
      },
    }),
  };

  return dataToTrack;
};

const getPhotosByCompany = (
  photos: google.maps.places.PlacePhoto[] | undefined,
  companyName: string | undefined,
): {
  count: number;
  url: string | undefined;
} | null => {
  if (!photos || !companyName) {
    return null;
  }
  const photosByCompany = photos.filter(
    photo => extractLabelText(photo.html_attributions[0]) === companyName,
  );
  const url =
    photosByCompany.length > 0 ? photosByCompany[0].getUrl() : undefined;

  return {
    count: photosByCompany.length,
    url,
  };
};

const decodeHTMLEntities = (text: string): string => {
  const element = document.createElement("div");
  element.innerHTML = text;
  return element.textContent || "";
};

const getHighestReview = (
  reviews: google.maps.places.PlaceReview[] | undefined,
): google.maps.places.PlaceReview | null => {
  const reviewsWithTextAndRating = reviews?.filter(
    review => review.text && review.rating,
  );

  if (!reviewsWithTextAndRating?.length) return null;

  return reviewsWithTextAndRating.reduce((highest, review) => {
    const highestRating = highest.rating ?? 0;
    const reviewRating = review.rating ?? 0;
    return reviewRating > highestRating ? review : highest;
  });
};

const extractLabelText = (htmlString: string): string => {
  // matches an HTML anchor tag and captures the text content inside it
  const regex = /<a[^>]*>([^<]+)<\/a>/;
  const match = htmlString?.match(regex);
  const extractedText = match ? match[1] : "";

  return decodeHTMLEntities(extractedText);
};

export function mapPlaceToCompany(
  place: google.maps.places.PlaceResult,
): AutocompleteCompany {
  return {
    name: place.name ?? "",
    website: place.website ?? "",
    placeId: place.place_id ?? "",
    photos: place.photos,
    reviews: place.reviews,
  };
}

export const getCountryCode = (country: string) => {
  switch (country.toLowerCase()) {
    case "canada":
      return "CA";
    case "us":
      return "US";
    default:
      return undefined;
  }
};
