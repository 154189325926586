import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { TwoColumn } from "jobber/setupWizard/layouts/TwoColumn";
import type { SetupWizardStepProps } from "jobber/setupWizard/types";
import { BusinessPageForm } from "jobber/setupWizard/components/BusinessPage/components/BusinessPageForm";
import { FormColumn } from "jobber/setupWizard/layouts/FormColumn";
import { MediaColumn } from "jobber/setupWizard/layouts/MediaColumn";
import { MaskingTapeFact } from "jobber/setupWizard/components/MaskingTapeFact";
import { facts } from "jobber/setupWizard/components/MaskingTapeFact/facts";
import { Amplitude } from "~/utilities/analytics/Amplitude";
import { ANALYTICS_EVENTS } from "jobber/setupWizard/analytics";
import { SetupWizardContext } from "jobber/setupWizard/context";
import { getIndustrySpecificContent } from "jobber/setupWizard/utils";
import { messages } from "./messages";

export function BusinessPage(stepProps: SetupWizardStepProps) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    Amplitude.TRACK_EVENT(ANALYTICS_EVENTS.viewedSetupWizard.eventName, {
      step: ANALYTICS_EVENTS.viewedSetupWizard.validSteps.business,
    });
  }, []);

  const { wizardData } = useContext(SetupWizardContext);
  const {
    businessPage: { heading, backgroundImage },
  } = getIndustrySpecificContent(wizardData.industry);

  return (
    <TwoColumn
      leftComponent={
        <FormColumn
          heading={heading && formatMessage(heading)}
          copy={formatMessage(messages.businessPageCopy)}
          form={<BusinessPageForm {...stepProps} />}
        />
      }
      rightComponent={
        <MediaColumn backgroundImage={backgroundImage}>
          <MaskingTapeFact fact={facts.defaultFact.copy} />
        </MediaColumn>
      }
    />
  );
}
