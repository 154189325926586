import { useCallback, useRef, useState } from "react";
import { useDatadogLogger } from "~/utilities/errors/Datadog/DatadogErrorBoundaryProvider";

export const usePreloadImages = () => {
  const [isPreloadingImages, setIsPreloadingImages] = useState(false);
  const { logError } = useDatadogLogger();
  const loadedImagesRef = useRef(new Set());

  const preloadImages = useCallback(
    async (srcs: string[]) => {
      setIsPreloadingImages(true);

      const promises = srcs.map(src => {
        if (loadedImagesRef.current.has(src)) {
          return Promise.resolve(true);
        }

        return new Promise(resolve => {
          const img = new Image();
          img.onload = () => {
            loadedImagesRef.current.add(src);
            resolve(true);
          };
          img.onerror = () => resolve(false);
          img.src = src;
        });
      });

      try {
        const results = await Promise.all(promises);
        const allImagesLoaded = results.every(result => result === true);
        if (!allImagesLoaded) {
          throw new Error("Not all images loaded");
        }
      } catch (error) {
        logError("Error Preloading Images", {}, error);
      } finally {
        setIsPreloadingImages(false);
      }
    },
    [logError],
  );

  return {
    isPreloadingImages,
    preloadImages,
  };
};
