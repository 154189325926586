import React, { useCallback, useState } from "react";
import { Content } from "@jobber/components/Content";
import { Text } from "@jobber/components/Text";
import { Flex, Heading, Icon, Modal, Tooltip } from "@jobber/components";
import { useIntl } from "react-intl";
import { parseISO } from "date-fns";
import { useFormatCurrency } from "jobber/dashboard/components/utils/useFormatCurrency";
import { TextActionButton } from "~/components/TextActionButton/TextActionButton";
import type CreditCard from "jobber/payments_sca/interfaces/CreditCard";
import { useJobberPayments } from "~/utilities/contexts/internal/useJobberPayments";
import { messages } from "./messages";
import styles from "./UpcomingPayouts.module.css";
import { UpcomingPayout } from "./components/UpcomingPayout";
import { UpcomingPayoutsModalContent } from "./components/UpcomingPayoutModalContent";
import { InstantPayoutModal } from "./components/InstantPayoutModal/InstantPayoutModal";
import { EventTypes, trackEventWithAmplitude } from "../../utils";

interface UpcomingPayoutsProps {
  processingPayout: number;
  instantPayoutDebitCardInfo?: CreditCard;
  instantPayoutFeeMultiplier: string;
  instantPayout: string;
  instantPayoutRequestedLast24Hours: string;
  hasValidPayoutDebitCard: boolean;
  expectedPayouts: {
    arrivalDate: string;
    id: string;
    netAmount: number;
  }[];
  refetchValues(): void;
}

export function UpcomingPayouts({
  processingPayout,
  instantPayout,
  expectedPayouts,
  instantPayoutDebitCardInfo,
  instantPayoutFeeMultiplier,
  hasValidPayoutDebitCard,
  instantPayoutRequestedLast24Hours,
  refetchValues,
}: UpcomingPayoutsProps) {
  const { formatMessage } = useIntl();
  const currencyFormatter = useFormatCurrency();
  const totalExpectedPayouts = expectedPayouts.reduce(
    (sum, payout) => sum + payout.netAmount,
    0,
  );

  const sortedExpectedPayouts = [...expectedPayouts].sort(
    (a, b) =>
      parseISO(a.arrivalDate).getTime() - parseISO(b.arrivalDate).getTime(),
  );
  const [firstPayout, secondPayout] = sortedExpectedPayouts;
  const nextTwoPayouts = [firstPayout, secondPayout].filter(
    payout => payout !== undefined,
  );

  const [isUpcomingPaymentsModalOpen, setUpcomingPaymentsModalOpen] =
    useState(false);

  const handleOpenUpcomingPaymentsModal = useCallback(() => {
    trackEventWithAmplitude({
      eventType: EventTypes.Modal,
      eventSource: "Upcoming payouts",
    });
    setUpcomingPaymentsModalOpen(true);
  }, []);

  const {
    permissions: { canViewInstantPayouts },
  } = useJobberPayments();

  return (
    <>
      <Content spacing="smaller">
        <Flex template={["shrink", "shrink"]} gap="smallest">
          <Text size="small">{formatMessage(messages.onitsWay)}</Text>
          <Tooltip
            preferredPlacement="bottom"
            message={formatMessage(messages.onitsWayToolTipText)}
          >
            <span
              onMouseEnter={() => {
                trackEventWithAmplitude({
                  eventType: EventTypes.Tooltip,
                  eventSource: "On its way to your bank",
                });
              }}
            >
              <Icon name="help" size="small" />
            </span>
          </Tooltip>
        </Flex>
        <Heading level={2}>
          {totalExpectedPayouts > 0
            ? currencyFormatter(totalExpectedPayouts / 100)
            : "-"}
        </Heading>
      </Content>

      {nextTwoPayouts.length > 0 && (
        <Content spacing="smaller">
          {nextTwoPayouts.map(expectedPayout => (
            <UpcomingPayout
              expectedPayout={expectedPayout}
              key={expectedPayout.id}
            />
          ))}
          {sortedExpectedPayouts.length > 2 && (
            <span className={styles.buttonContainer}>
              <TextActionButton
                label={formatMessage(messages.allUpcomingPayouts)}
                onClick={handleOpenUpcomingPaymentsModal}
                size="small"
              />
            </span>
          )}
        </Content>
      )}

      <Content spacing="smaller">
        <Flex template={["shrink", "shrink"]} gap="smallest">
          <Text size="small">{formatMessage(messages.processingPayout)}</Text>
          <Tooltip
            preferredPlacement="bottom"
            message={formatMessage(messages.processingPayoutToolTipText)}
          >
            <span
              onMouseEnter={() => {
                trackEventWithAmplitude({
                  eventType: EventTypes.Tooltip,
                  eventSource: "Processing payout",
                });
              }}
            >
              <Icon name="help" size="small" />
            </span>
          </Tooltip>
        </Flex>
        {processingPayout > 0 ? (
          <Heading level={2}>{currencyFormatter(processingPayout)}</Heading>
        ) : (
          <Heading level={2}>-</Heading>
        )}
        {canViewInstantPayouts && (
          <InstantPayoutModal
            instantAvailableAmount={instantPayout}
            instantPayoutFeeMultiplier={instantPayoutFeeMultiplier}
            debitCard={instantPayoutDebitCardInfo}
            debitCardIsValid={hasValidPayoutDebitCard}
            instantPayoutRequestedLast24Hours={
              instantPayoutRequestedLast24Hours
            }
            onPayoutSucceed={refetchValues}
          />
        )}
      </Content>
      <Modal
        open={isUpcomingPaymentsModalOpen}
        onRequestClose={() => setUpcomingPaymentsModalOpen(false)}
      >
        <UpcomingPayoutsModalContent expectedPayouts={sortedExpectedPayouts} />
      </Modal>
    </>
  );
}
